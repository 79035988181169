import axios from "axios";

import { TTP_API_URL } from "../config";

import {
  getRequestCancellationToken,
  getRequestConfig,
  generateCancellationTokenSource,
  throwCatchedError,
} from "../utils";

let getClientsCTS;
let getAuthorsListCTS;
let getUaMessagesCTS;
let getDelegationTypeCTS;

export const getFiduciary = ({ token, fiduciaryId, userId = null }) => {
  const filter = [];
  if (fiduciaryId) {
    filter.push({ property: "id", value: fiduciaryId, operator: "eq" });
  } else {
    filter.push({ property: "type", value: "FIDUCIARY,SH", operator: "in" });
    if (userId) {
      filter.push({ property: "user.id", value: userId, operator: "eq" });
    }
  }

  const fields = [
    "*",
    // "defaultChannelsCC",
    // "iban",
    "url",
    "sector",
    "avatarWebPath",
    "website",
    "legalRepresentative",
    "uaOrder",
    "subscription",
    "uaSettings",
    "studentDomainEmails",
    // "transferModes",
    // "hasFiduciariesAdmin",
  ];

  const requestUrl = `${TTP_API_URL}/organization/organization`;

  const params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
  };
  if (fiduciaryId || userId) {
    params.workspace = "user";
  }

  return axios.get(requestUrl, {
    params,
  });
};

export const getFiduciaries = ({
  token,
  name,
  sort,
  status,
  page,
  start,
  limit,
}) => {
  const filter = [
    // { property: "role.type", value: "LEGAL_REPRESENTATIVE", operator: "eq" },
    { property: "type", value: "FIDUCIARY,SH", operator: "in" },
  ];
  if (status) {
    filter.push({ property: "uaStatus", value: status, operator: "eq" });
  }
  if (name) {
    filter.push({ property: "name", value: name, operator: "like" });
  }

  const fields = [
    "*",
    "url",
    "sector",
    "avatarWebPath",
    "website",
    "allLegalRepresentative",
    "uaSettings",
    "membershipOrder",
    "note",
    "createdAt",
  ];

  const requestUrl = `${TTP_API_URL}/organization/organization`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
      start: page ? (page - 1) * limit : start,
      limit,
      sort: JSON.stringify([sort]),
      workspace: "admin",
    },
  });
};

export const getOrganizationSettings = (token, fiduciaryId) => {
  const fields = ["*"];

  const requestUrl = `${TTP_API_URL}/organization/organization/get-organization-settings/${fiduciaryId}`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
    },
  });
};

export const getClients = ({
  token,
  search,
  sort,
  page,
  clientStatus,
  limit,
}) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getClientsCTS,
    cancellationTokenSource
  );
  getClientsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/folder`;

  let params = {
    access_token: token,
    sort: JSON.stringify([sort]),
    fields: "*,adminisId,legalRepresentative,delegations,contacts",
    start: page ? (page - 1) * limit : 1,
    limit,
  };

  let filters = [];
  if (search) {
    filters.push({ property: "name", value: search, operator: "like" });
  }
  if (clientStatus) {
    filters.push({
      property: "uaClientStatus",
      value: clientStatus,
      operator: "eq",
    });
  }
  if (filters.length > 0) {
    params.filter = JSON.stringify(filters);
  }

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const saveClient = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/folder`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("legalRepresentative[email][0][email]", data.contactEmail);
  formData.append("legalRepresentative[firstName]", data.contactFirstName);
  formData.append("legalRepresentative[lastName]", data.contactLastName);
  formData.append("legalRepresentative[phone][0][number]", data.contactPhone);
  formData.append("uen", data.uen);
  formData.append("name", data.name);
  formData.append("legalForm", data.legalForm);
  formData.append("languagePreferences", data.languagePreferences.join(","));

  if (data.isEdit && data.id) {
    formData.append("id", data.id);
  }
  if (!data.isEdit) {
    formData.append("uaClientStatus", "PENDING");
  }
  if (data.contactId) {
    formData.append("legalRepresentative[id]", data.contactId);
  }
  if (data.contactEmailId) {
    formData.append("legalRepresentative[email][0][id]", data.contactEmailId);
  }
  if (data.contactPhoneId) {
    formData.append("legalRepresentative[phone][0][id]", data.contactPhoneId);
  }
  if (data.owner) {
    formData.append("owner", data.owner);
    // formData.append("allowUpdateForeignFolder", 1);
  }

  return axios.post(requestUrl, formData);
};

export const saveFiduciary = ({
  token,
  files,
  id,
  contactId,
  contactEmailId,
  name,
  uen,
  abbreviation,
  address1,
  zipCode,
  country,
  city,
  tva,
  legalForm,
  sector,
  url,
  languagePreferences,
  onBoardingStep,
  communityType,
}) => {
  const requestUrl = `${TTP_API_URL}/organization/organization`;

  let formData = new FormData();
  formData.append("access_token", token);
  if (files.length > 0) formData.append("avatar", files[0]);
  formData.append("name", name);
  formData.append("website", url);
  formData.append("uen", uen);
  formData.append("zipCode", zipCode);
  formData.append("country", country);
  formData.append("city", city);
  formData.append("type", "FIDUCIARY");
  formData.append("address1", address1);
  formData.append("abbreviation", abbreviation);

  if (tva) {
    formData.append("tva", tva);
  }
  if (sector) {
    formData.append("sector", sector);
  }
  if (legalForm) {
    formData.append("legalForm", legalForm);
  }
  if (languagePreferences) {
    formData.append("languagePreferences", languagePreferences);
  }
  if (id) {
    formData.append("id", id);
  }
  if (onBoardingStep) {
    formData.append("onBoardingStep", onBoardingStep);
  }
  if (contactId) {
    formData.append("legalRepresentative[id]", contactId);
  }
  if (contactEmailId) {
    formData.append("legalRepresentative[email][0][id]", contactEmailId);
  }
  if (communityType) {
    formData.append("communityType", communityType);
  }

  return axios.post(requestUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const saveFolderStep = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/organization`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("onBoardingStep", data.step);

  return axios.post(requestUrl, formData);
};

export const saveOECCBBCollab = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/saveOECCBBCollab`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("userId", data.userId);

  return axios.post(requestUrl, formData);
};

export const saveFiduciaryStatus = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/organization`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("uaStatus", data.uaStatus);
  formData.append("status", "CREATED");

  if (data.uaMode) {
    formData.append("uaMode", data.uaMode);
    if (data.uaMode === "OECCBB_MEMBER") {
      formData.append("uaSettings", 7);
    }
  }

  if (data.hasFiduciaryPlan) {
    formData.append("hasFiduciaryPlan", 1);
  }

  if (data.onBoardingStep) {
    formData.append("onBoardingStep", data.onBoardingStep);
  }

  if (data.note) {
    formData.append("note", data.note);
  }

  return axios.post(requestUrl, formData);
};

export const saveFolderUaSettings = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/organization`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("uaSettings", data.uaSettings);

  return axios.post(requestUrl, formData);
};

export const deleteClient = (token, clientId) => {
  let requestUrl = `${TTP_API_URL}/organization/folder/${clientId}`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const importClients = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/recognizer/folder`;

  var formData = new FormData();
  formData.append("access_token", token);

  if (data.data) {
    formData.append("data", data.data);
  }

  if (data.file) {
    formData.append("file", data.file);
  }

  return axios.post(requestUrl, formData);
};

export const getAuthors = (token, limit, cancellationTokenSource = null) => {
  let requestCancellationToken = getRequestCancellationToken(
    getAuthorsListCTS,
    cancellationTokenSource
  );

  let filter = [
    {
      property: "organization",
      value: 9,
      operator: "eq",
    },
  ];

  let fields = ["*", "user"];

  const requestUrl = `${TTP_API_URL}/blog/author`;

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    limit: limit ? limit : 10,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const saveCommunityPortalsSettings = ({ token, communityId, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/save-hub-portals-preferences?access_token=${token}&organization_id=${communityId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("organization_id", communityId);
  formData.append("portals", JSON.stringify(data.portals));
  formData.append("subdomain", data.subdomain);

  return axios.post(requestUrl, formData);
};

export const saveAllowedApps = ({ token, allowedApps, organization }) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/save-allowed-apps`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("organization", organization);
  formData.append("allowedApps", allowedApps);

  return axios.post(requestUrl, formData);
};

export const saveAllowedTabs = ({ token, allowedTabs, organization }) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/save-allowed-tabs`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("organization", organization);
  formData.append("allowedTabs", allowedTabs);

  return axios.post(requestUrl, formData);
};

export const getPublicOrganization = (token, subdomain) => {
  const filter = [
    { property: "subdomain", value: subdomain, operator: "like" },
  ];

  const requestUrl = `${TTP_API_URL}/organization/organization/getPublicOrganization`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
    },
  });
};

export const saveFolder = ({
  token,
  id,
  name,
  uen,
  address1,
  zipCode,
  city,
  officeSize,
  officeType,
  officeStatus,
  uaStatus,
  agreation,
  isOeccbbMember = false,
}) => {
  const requestUrl = `${TTP_API_URL}/organization/organization`;

  let formData = new FormData();
  if (id) formData.append("id", id);
  formData.append("access_token", token);
  formData.append("name", name);
  formData.append("uen", uen);
  formData.append("zipCode", zipCode);
  formData.append("country", "BE");
  formData.append("city", city);
  formData.append("type", "FIDUCIARY");
  formData.append("status", "CREATED");
  formData.append("uaStatus", uaStatus);
  formData.append("address1", address1);
  formData.append("officeSize", officeSize);
  formData.append("officeType", officeType);
  formData.append("officeStatus", officeStatus);
  formData.append("agreation", JSON.stringify(agreation));
  formData.append("origin", "ua");

  if (isOeccbbMember) {
    formData.append("uaMode", "OECCBB_MEMBER");
    formData.append("uaSettings", 7);
  }

  return axios.post(requestUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const saveAppsSettings = ({ token, communityId, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/save-preferences?access_token=${token}&organization_id=${communityId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("organizationId", communityId);
  formData.append("appName", data.appName);
  formData.append("tools", JSON.stringify(data.tools));

  if (data.lRId) {
    formData.append("lRId", data.lRId);
  }

  return axios.post(requestUrl, formData);
};

export const saveAppsPreferences = ({ token, communityId, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/save-ua-preferences?access_token=${token}&organization_id=${communityId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("organizationId", communityId);
  formData.append("appName", data.appName);
  formData.append("source", JSON.stringify(data.source));

  if (data.lRId) {
    formData.append("lRId", data.lRId);
  }

  return axios.post(requestUrl, formData);
};

export const saveImportClients = (token, file, organizationId) => {
  let requestUrl = `${TTP_API_URL}/organization/folder/ua-import`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("file", file);
  formData.append("organization", organizationId);

  return axios.post(requestUrl, formData);
};

export const getFiduciaryByUen = ({ token, uen }) => {
  const filter = [];
  filter.push({
    property: "uen",
    value: uen.replace(/\.|\s|BE|be/g, ""),
    operator: "eq",
  });

  const fields = [
    "*",
    // "defaultChannelsCC",
    // "iban",
    //"url",
    //"sector",
    //"avatarWebPath",
    //"website",
    //"legalRepresentative",
    // "transferModes",
    // "hasFiduciariesAdmin",
  ];

  const requestUrl = `${TTP_API_URL}/organization/organization`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
      workspace: "user",
    },
  });
};

export const updateFolder = async (token, field, data, id) => {
  const requestUrl = `${TTP_API_URL}/organization/organization`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("id", id);

  if (field === "name") {
    formData.append("name", data.name);
  }
  if (field === "activity_area") {
    formData.append("sector", data.sector);
  }
  if (field === "legal_form") {
    formData.append("legalForm", data.legalForm);
  }
  if (field === "url_link") {
    formData.append("website", data.url);
  }
  if (field === "abbreviation") {
    formData.append("abbreviation", data.abbreviation);
  }
  if (field === "address") {
    formData.append("address1", data.address);
  }
  if (field === "zipCode") {
    formData.append("zipCode", data.zipCode);
  }
  if (field === "city") {
    formData.append("city", data.city);
  }
  if (field === "language") {
    formData.append("languagePreferences", data.language);
  }
  if (field === "approval_num") {
    formData.append("agreation", data.agreation);
  }
  if (field === "avatar") {
    formData.append("avatar", data.avatar);
  }
  if (field === "studentDomainEmails") {
    formData.append("studentDomainEmails", data.studentDomainEmails);
  }

  return axios.post(requestUrl, formData);
};

export const addOrder = ({ token, language, organizationId, period }) => {
  const requestUrl = `${TTP_API_URL}/organization/add-ua-order`;
  var formData = new FormData();
  formData.append("access_token", token);

  formData.append("organization_id", organizationId);
  formData.append("language", language);
  formData.append("period", period);

  return axios.post(requestUrl, formData);
};

export const addUpgradeOrder = ({
  token,
  organizationId,
  countCollaborators,
  period,
  language,
}) => {
  const requestUrl =
    period === "YEARLY"
      ? `${TTP_API_URL}/organization/add-ua-yearly-upgrade-order`
      : `${TTP_API_URL}/organization/add-ua-upgrade-order`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("organization_id", organizationId);
  formData.append("countCollaborators", countCollaborators);
  formData.append("language", language);

  return axios.post(requestUrl, formData);
};

export const getRateBySize = (token, organizationId) => {
  const requestUrl = `${TTP_API_URL}/organization/folder/ua-rate-size`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      id: organizationId,
    },
  });
};

export const getUpgradeRate = (token, organizationId, nbCollaborators = 0) => {
  const requestUrl = `${TTP_API_URL}/organization/folder/ua-upgrade-rate`;

  let params = {
    access_token: token,
    id: organizationId,
  };
  if (nbCollaborators > 0) {
    params.nbCollaborators = nbCollaborators;
  }

  return axios.get(requestUrl, {
    params,
  });
};

export const getClientsByUen = ({ token, uens }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getClientsCTS,
    cancellationTokenSource
  );
  getClientsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/organization`;

  let params = {
    access_token: token,
    fields: "*,legalRepresentative,owner",
    workspace: "user",
  };

  params.filter = JSON.stringify([
    { property: "uen", value: uens, operator: "in" },
    {
      property: "type",
      value: ["BUSINESS", "FIDUCIARY", "SH"],
      operator: "in",
    },
  ]);

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const subscribeFiduciary = (token, data) => {
  const requestUrl = `${TTP_API_URL}/data/subscribeFiduciary`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("uen", data.uen);
  formData.append("client", data.id);
  formData.append("user", data.userId);

  return axios.post(requestUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getFolderInfoByUen = ({ token, uen }) => {
  const requestUrl = `${TTP_API_URL}/organization/folder/validate-uen`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      uen,
    },
  });
};

export const getFolderByUen = ({ token, uen }) => {
  const requestUrl = `${TTP_API_URL}/organization/folder/find-by-uen`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      uen: uen.replace(/\.|\s|BE|be/g, ""),
    },
  });
};

export const getFolderByUens = (token, uens) => {
  let fields = ["*", "owner"];

  const requestUrl = `${TTP_API_URL}/organization/folder`;

  const filter = [
    {
      property: "uen",
      value: uens,
      operator: "in",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
      workspace: "admin",
    },
  });
};

export const getPrivacyRubrics = ({ token, communityId = null }) => {
  const requestUrl = `${TTP_API_URL}/media/privacy-rubric`;

  let filters = [];
  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
  }
  const sort = [
    {
      property: "order",
      dir: "asc",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      sort: JSON.stringify(sort),
      fields: "*,versions",
    },
  });
};

export const getFolderSpecialties = ({ token, communityId }) => {
  const requestUrl = `${TTP_API_URL}/organization/folder-specialty`;

  let filters = [];
  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
    },
  });
};

export const saveFolderSpecialties = async ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/folder-specialty`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("organization", data.organization);
  formData.append("specialties", data.specialties);
  formData.append("favorites", data.favorites);

  let id = null;
  try {
    const res = await getFolderSpecialties({
      token,
      communityId: data.organization,
    });
    id = res.data.data[0].id;
  } catch (error) {}
  if (id) formData.append("id", id);

  return axios.post(requestUrl, formData);
};

export const setUsedSpecialties = async ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/specialty/used`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("list", data.list);

  return axios.post(requestUrl, formData);
};

export const createSpecialtyType = ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/specialty-type`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("nameFr", data.name.fr);
  formData.append("nameEn", data.name.en);
  formData.append("nameNl", data.name.nl);
  formData.append("hasChoices", data.hasChoices ? 1 : 0);
  formData.append("isActivated", data.isActivated ? 1 : 0);

  if (data.id) formData.append("id", data.id);

  return axios.post(requestUrl, formData);
};

export const getSpecialties = ({ token }) => {
  const fields = ["*", "specialties", "icon"];

  const requestUrl = `${TTP_API_URL}/organization/specialty-type`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
    },
  });
};

export const createSpecialty = ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/specialty`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("nameFr", data.name.fr);
  formData.append("nameEn", data.name.en);
  formData.append("nameNl", data.name.nl);
  formData.append("isMultiSelect", data.isMultiSelect ? 1 : 0);
  if (data.type) formData.append("type", data.type);
  if (data.parent) formData.append("parent", data.parent);
  if (data.icon) formData.append("icon", data.icon);
  if (data.nbrFavorites) formData.append("nbrFavorites", data.nbrFavorites);

  if (data.id) formData.append("id", data.id);

  return axios.post(requestUrl, formData);
};

export const deleteSpecialtyType = ({ token, id }) => {
  const requestUrl = `${TTP_API_URL}/organization/specialty-type/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const deleteSpecialty = ({ token, id }) => {
  const requestUrl = `${TTP_API_URL}/organization/specialty/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const createUaContactMessage = ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-contact`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("organization", data.organization);
  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("subject", data.subject);
  formData.append("message", data.message);

  return axios.post(requestUrl, formData);
};

export const getUaContactMessage = ({ token, communityId, search, start }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getUaMessagesCTS,
    cancellationTokenSource
  );
  getUaMessagesCTS = cancellationTokenSource;

  const requestUrl = `${TTP_API_URL}/organization/ua-contact`;

  let filters = [];
  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
  }

  if (search.trim().length > 0) {
    filters.push({
      property: "search",
      value: search,
      operator: "eq",
    });
  }

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  const params = {
    access_token: token,
    filter: JSON.stringify(filters),
    sort: JSON.stringify(sort),
    limit: 10,
    start,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};
export const readMessage = ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-contact`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("isRead", 1);

  return axios.post(requestUrl, formData);
};

export const getBillingSubscriptionId = ({ token, id }) => {
  const requestUrl = `${TTP_API_URL}/organization/folder/ua-billing-subscription-id`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      id,
    },
  });
};

export const getFolderBySubdomain = ({ token, subdomain }) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/subdomain`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      subdomain,
    },
  });
};

export const saveDelegationType = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/delegationType`;
  var formData = new FormData();
  formData.append("access_token", token);
  for (let key in data) {
    if (key !== "id") {
      formData.append(key, data[key]);
    }
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  return axios.post(requestUrl, formData);
};

export const saveDelegation = (token, folderIds, userId, typeId) => {
  let requestUrl = `${TTP_API_URL}/organization/delegation/saveAll`;
  var formData = new FormData();
  formData.append("access_token", token);

  formData.append("user", userId);
  formData.append("folders", folderIds.toString());
  formData.append("type", typeId);

  return axios.post(requestUrl, formData);
};

export const getDelegationTypes = ({
  token,
  organizationId,
  search,
  status,
  page,
  limit,
}) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getDelegationTypeCTS,
    cancellationTokenSource
  );
  // getDelegationTypeCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/delegationType`;

  let params = {
    access_token: token,
    // sort: JSON.stringify([sort]),
    // fields: "*,adminisId,legalRepresentative",
    start: page ? (page - 1) * limit : 0,
    limit: limit ?? 20,
  };

  const filters = [
    {
      property: "organization",
      value: organizationId,
      operator: "eq",
    },
  ];

  if (status) {
    filters.push({ property: "status", value: status, operator: "eq" });
  }

  if (search) {
    filters.push({ property: "titleFr", value: search, operator: "like" });
    filters.push({ property: "titleEn", value: search, operator: "like" });
    filters.push({ property: "titleNl", value: search, operator: "like" });
  }

  if (filters.length > 0) {
    params.filter = JSON.stringify(filters);
  }

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getDelegations = ({ token, folderIds, page, limit }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getDelegationTypeCTS,
    cancellationTokenSource
  );
  getDelegationTypeCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/delegation`;

  let params = {
    access_token: token,
    // sort: JSON.stringify([sort]),
    // fields: "*,adminisId,legalRepresentative",
    start: page ? (page - 1) * limit : 1,
    limit: limit ?? 20,
  };

  const filters = [
    {
      property: "folder",
      value: folderIds.toString(),
      operator: "in",
    },
  ];

  if (filters.length > 0) {
    params.filter = JSON.stringify(filters);
  }

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};
// export const getUsers = ({ token, search, organizationId }) => {
//   let cancellationTokenSource = generateCancellationTokenSource();
//   // getGroupsCTS
//   let requestCancellationToken = getRequestCancellationToken(
//     getCollaboratorsCTS,
//     cancellationTokenSource
//   );
//   getCollaboratorsCTS = cancellationTokenSource;

//   let requestUrl = `${TTP_API_URL}/organization/user`;

//   const filter = [
//     {
//       property: "organization.id",
//       value: organizationId,
//       operator: "eq",
//     },
//   ];

//   if (search) {
//     filter.push({ property: "name", value: search, operator: "like" });
//     filter.push({ property: "email.main", value: 1, operator: "eq" });
//   }

//   let params = {
//     access_token: token,
//     fields: "*",
//     start: 0,
//     filter: JSON.stringify(filter),
//     limit: 20,
//     workspace: "ua",
//   };

//   let requestConfig = getRequestConfig(params, requestCancellationToken);
//   return axios.get(requestUrl, requestConfig).catch(function (thrown) {
//     throwCatchedError(thrown);
//   });
// };

export const deleteDelegationType = (token, typeId) => {
  let requestUrl = `${TTP_API_URL}/organization/delegationType/${typeId}`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const updateDelegationTypeStatus = (token, id, status) => {
  let requestUrl = `${TTP_API_URL}/organization/delegationType`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("id", id);
  formData.append("status", status);

  return axios.post(requestUrl, formData);
};

export const deleteDelegation = (token, typeId) => {
  let requestUrl = `${TTP_API_URL}/organization/delegation/${typeId}`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const getContactClient = (token, navCommunityId, folderIds) => {
  let requestUrl = `${TTP_API_URL}/organization/contact-client`;

  const filter = [
    { property: "type", value: "CONTACT", operator: "eq" },
    { property: "client", value: navCommunityId, operator: "eq" },
    { property: "ownerClient", value: folderIds, operator: "in" },
  ];

  const fields = ["*", "contact"];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
    },
  });
};

export const saveClientContact = ({
  token,
  navCommunityId,
  contactId,
  folderId,
}) => {
  let requestUrl = `${TTP_API_URL}/organization/contact-client/add`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("contact", contactId);
  formData.append("client", navCommunityId);
  formData.append("ownerClient", folderId);

  return axios.post(requestUrl, formData);
};

export const getMemberships = ({
  token,
  year,
  excludeYear = null,
  previousYear = null,
  sort,
  status,
  formulaIds,
  page,
  start,
  limit,
  source,
  search,
  without_associates = false,
  addOnboarding = false,
}) => {
  const filter = [{ property: "status", value: status, operator: "in" }];
  if (previousYear) {
    filter.push({
      property: "year",
      value: previousYear.join(),
      operator: "in",
    });
  } else {
    filter.push({ property: "year", value: year, operator: "eq" });
  }
  if (excludeYear) {
    filter.push({
      property: "excludeYear",
      value: excludeYear,
      operator: "eq",
    });
  }
  if (formulaIds.length > 0) {
    filter.push({
      property: "formulas",
      value: formulaIds.join(),
      operator: "in",
    });
  }
  if (source) {
    filter.push({ property: "source", value: source, operator: "eq" });
  }
  if (search) {
    filter.push({ property: "name", value: search, operator: "like" });
  }

  const fields = ["*", "user"];

  const requestUrl = `${TTP_API_URL}/organization/membership-order/list`;

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    start: page ? (page - 1) * limit : start,
    limit,
    sort: JSON.stringify([sort]),
    workspace: "admin",
  };
  if (without_associates) {
    params.without_associates = 1;
  }
  if (addOnboarding) {
    params.add_onboarding = 1;
  }

  return axios.get(requestUrl, {
    params,
  });
};

export const getMembershipStats = ({ token, year, status, source }) => {
  const requestUrl = `${TTP_API_URL}/organization/membership-order/stats`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      year,
      status,
      source,
    },
  });
};

export const getUaMemberships = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership/home`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};

export const addBulkMemberShipOrder = (token, file) => {
  let requestUrl = `${TTP_API_URL}/organization/membership-order/add-bulk-order`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("file", file);

  return axios.post(requestUrl, formData);
};

export const addMemberShipOrder = ({
  token,
  language,
  memberShip,
  uen,
  organization,
  organizationId,
  address,
  postalCode,
  city,
  subjectToVAT,
  memberShipOrder,
  user,
  orderFromOECCBB = false,
}) => {
  const requestUrl = `${TTP_API_URL}/organization/add-membership-order`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("memberShip", memberShip);
  formData.append("language", language);
  formData.append("uen", uen);
  formData.append("organization", organization);
  formData.append("address", address);
  formData.append("postalCode", postalCode);
  formData.append("country", city);
  formData.append("billingSubjectToVAT", subjectToVAT ? 1 : 0);
  formData.append("source", "UA");
  if (orderFromOECCBB) {
    formData.append("orderFromOECCBB", 1);
  }
  if (memberShipOrder) {
    formData.append("memberShipOrder", memberShipOrder.id);
  }
  if (organizationId) {
    formData.append("organizationId", organizationId);
  }
  if (user) {
    formData.append("user", user);
  }

  return axios.post(requestUrl, formData);
};

export const saveFolderNote = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/organization`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("note", data.note);

  return axios.post(requestUrl, formData);
};

export const saveDelegationPriority = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/delegationType/update-priorities`;
  var formData = new FormData();
  formData.append("access_token", token);

  formData.append("data", JSON.stringify(data));

  return axios.post(requestUrl, formData);
};
export const associateDelegationToFolders = (token, organization, year) => {
  let requestUrl = `${TTP_API_URL}/organization/delegation/associate-delegation`;
  var formData = new FormData();
  formData.append("access_token", token);

  formData.append("organization", organization);
  formData.append("year", year);

  return axios.post(requestUrl, formData);
  //return axios.post(requestUrl, formData, { timeout: 300000 });
};
