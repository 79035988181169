import axios from "axios";

import { TTP_API_URL } from "../config";

export const getUaSettings = ({ token, title, sort, page, start, limit }) => {
  const filter = [];

  if (title) {
    filter.push({ property: "title", value: title, operator: "like" });
  }

  const fields = ["*"];

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    start: page ? (page - 1) * limit : start,
    limit,
  };

  if (sort) {
    params.sort = JSON.stringify([sort]);
  }

  const requestUrl = `${TTP_API_URL}/organization/ua-settings`;

  return axios.get(requestUrl, {
    params,
  });
};

export const saveUaSettings = ({
  token,
  title,
  allowedApps,
  allowedTabs,
  id,
}) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-settings`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("title", title);
  formData.append("allowedApps", allowedApps);
  formData.append("allowedTabs", allowedTabs);

  if (id) {
    formData.append("id", id);
  }

  return axios.post(requestUrl, formData);
};

export const getHotels = ({ token, sort }) => {
  const fields = ["*"];

  let params = {
    access_token: token,
    fields: fields.join(","),
  };

  if (sort) {
    params.sort = JSON.stringify([sort]);
  }

  const requestUrl = `${TTP_API_URL}/organization/hotel`;

  return axios.get(requestUrl, {
    params,
  });
};

export const getMembersByHotel = ({
  token,
  hotel,
  distance,
  limit,
  nolimit = false,
  showMoreLimit,
  years,
  notMembersYears = [],
}) => {
  const fields = ["*"];

  let params = {
    access_token: token,
    fields: fields.join(","),
    hotel,
    distance,
    limit,
    years,
  };

  if (nolimit) {
    params.nolimit = 1;
  }

  if (notMembersYears.length > 0) {
    params.notMembersYears = notMembersYears;
  }

  if (showMoreLimit) {
    params.showMoreLimit = 1;
  }

  const requestUrl = `${TTP_API_URL}/organization/ua-membership/members-by-hotel`;

  return axios.get(requestUrl, {
    params,
  });
};

export const saveHotel = ({
  token,
  name,
  address,
  zipcode,
  city,
  latitude,
  longitude,
}) => {
  const requestUrl = `${TTP_API_URL}/organization/hotel`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("name", name);
  formData.append("address", address);
  formData.append("zipCode", zipcode);
  formData.append("city", city);
  formData.append("country", "BE");
  formData.append("latitude", latitude);
  formData.append("longitude", longitude);

  return axios.post(requestUrl, formData);
};
